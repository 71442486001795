import revive_payload_client_oxExqF7h47 from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_He4ilmFeuC from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xenDCTZyFQ from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DtSug9Sion from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dvJ0gTIacG from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LK71f31CUd from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2qfEoIXjCj from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/.nuxt/components.plugin.mjs";
import prefetch_client_BOOsGSiAfS from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_vT9JWWT9pN from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/auth.ts";
import autofocus_1LURlzQ7IF from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/autofocus.ts";
import chart_client_qQ92gke75l from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/chart.client.ts";
import dayjs_pGiXRjcsJO from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/dayjs.ts";
import dsfr_client_wnEKqTCG96 from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/dsfr.client.js";
import global_event_emitter_AhsutoD9go from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/global-event-emitter.ts";
import matomo_client_Ed2EqINkhd from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/matomo.client.ts";
import modal_UEFnUXzgTX from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/modal.ts";
import refresh_app_client_BPM4AstEHA from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/refresh-app.client.ts";
import sentry_client_shVUlIjFLk from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/sentry.client.ts";
import trpcClient_M8UiGTyzsx from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/plugins/trpcClient.ts";
export default [
  revive_payload_client_oxExqF7h47,
  unhead_He4ilmFeuC,
  router_xenDCTZyFQ,
  payload_client_DtSug9Sion,
  navigation_repaint_client_dvJ0gTIacG,
  check_outdated_build_client_LK71f31CUd,
  chunk_reload_client_2qfEoIXjCj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BOOsGSiAfS,
  auth_vT9JWWT9pN,
  autofocus_1LURlzQ7IF,
  chart_client_qQ92gke75l,
  dayjs_pGiXRjcsJO,
  dsfr_client_wnEKqTCG96,
  global_event_emitter_AhsutoD9go,
  matomo_client_Ed2EqINkhd,
  modal_UEFnUXzgTX,
  refresh_app_client_BPM4AstEHA,
  sentry_client_shVUlIjFLk,
  trpcClient_M8UiGTyzsx
]