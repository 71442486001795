import { default as emailsZb2GrNq9GOMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/emails.vue?macro=true";
import { default as integrationsR6oF9hqCg5Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/integrations.vue?macro=true";
import { default as overviewVWNEkBYOPqMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/overview.vue?macro=true";
import { default as index7szS5lLymRMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsw7f4Fvq8I4Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexxu5qLITqWeMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/structures/index.vue?macro=true";
import { default as indexrSjYYbie5wMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/users/[id]/index.vue?macro=true";
import { default as add8AgYJTT3d4Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/users/add.vue?macro=true";
import { default as indexYGpQcIzsDAMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/users/index.vue?macro=true";
import { default as adminsTB7Dal6IuMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin.vue?macro=true";
import { default as accountG1pmyCFxjYMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/account.vue?macro=true";
import { default as entourageiTNPVJuJ7gMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsrEsr6PUPqZMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthIZGpSx1YiKMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoojv9NHcx5yMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationUeExSXZkWOMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdk2Db5QrBgjMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printtnIcCM1PDFMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexkSYO7hxbvWMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/index.vue?macro=true";
import { default as editbi24jA0LLxMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as edituWF0Znl0oOMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editJipWn2Dt0WMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addlJDIJStTGjMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editUm8MRK2jpVMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addpv1RgiZWObMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editTpqbAGIJsRMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addPuMdPhIz4dMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editV6xkUDFPTKMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as index3Ipa2dmEYRMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/index.vue?macro=true";
import { default as addw0ut6umeVqMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/add.vue?macro=true";
import { default as historylPdyKryc9VMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/history.vue?macro=true";
import { default as printb1CGXm8JQ2Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewZAsQH7bAw9Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/overview.vue?macro=true";
import { default as partnersPFhoAYf5vVMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/partners.vue?macro=true";
import { default as statsoVLm2W2mcAMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/stats.vue?macro=true";
import { default as indexetPhQiFwXcMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/structure/index.vue?macro=true";
import { default as subjectsfLOvurp61PMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/structure/subjects.vue?macro=true";
import { default as usersqbTsG9tPZBMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/users.vue?macro=true";
import { default as app8IJfAiYwUOMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app.vue?macro=true";
import { default as adminBwhKJnY9w1Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/admin.vue?macro=true";
import { default as logindQcOD9o7UsMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/login.vue?macro=true";
import { default as logout7rvM3u1MnlMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/logout.vue?macro=true";
import { default as validate_45tokensLcDjpePUgMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/validate-token.vue?macro=true";
import { default as managerwCJ3DJ1xGbMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/cgu/manager.vue?macro=true";
import { default as userE0pVaYYx2JMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/cgu/user.vue?macro=true";
import { default as cguykkNK12vY5Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitexD8Ih94a3MMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45account2KwpfrroJwMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45user_45icIcBFb38cxuMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45userQeRUiugR5UMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/error/unknown-user.vue?macro=true";
import { default as index1XdBRfkhZeMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/index.vue?macro=true";
import { default as mentions_45legalesVB0Nxw8jyOMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteVAXFYgqKJsMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/politique-confidentialite.vue?macro=true";
import { default as registerldwaxOY4n0Meta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/register.vue?macro=true";
import { default as statistiqueswTu06gGSRpMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/statistiques.vue?macro=true";
import { default as storiesTFpzCJwWqVMeta } from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminsTB7Dal6IuMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overviewVWNEkBYOPqMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewVWNEkBYOPqMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: app8IJfAiYwUOMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationUeExSXZkWOMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationUeExSXZkWOMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printtnIcCM1PDFMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: indexkSYO7hxbvWMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexkSYO7hxbvWMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: editbi24jA0LLxMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editbi24jA0LLxMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: edituWF0Znl0oOMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: edituWF0Znl0oOMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editV6xkUDFPTKMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editV6xkUDFPTKMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printb1CGXm8JQ2Meta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewZAsQH7bAw9Meta?.name ?? "app-overview",
    path: "overview",
    meta: overviewZAsQH7bAw9Meta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminBwhKJnY9w1Meta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logindQcOD9o7UsMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logout7rvM3u1MnlMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokensLcDjpePUgMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguykkNK12vY5Meta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitexD8Ih94a3MMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45account2KwpfrroJwMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45icIcBFb38cxuMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/error/unknown-user-ic.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userQeRUiugR5UMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index1XdBRfkhZeMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesVB0Nxw8jyOMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteVAXFYgqKJsMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerldwaxOY4n0Meta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiqueswTu06gGSRpMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesTFpzCJwWqVMeta || {},
    component: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/pages/stories.vue").then(m => m.default || m)
  }
]