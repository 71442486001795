import validate from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/build/ff7be446-e19f-4a35-9cf1-236500a00160/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.13.0_jiti@1.21.6__ioredis@5.4.1_6rxlk3g4ag25df2udzswaucygu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/middleware/auth.ts"),
  cgu: () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/middleware/cgu.ts"),
  "skip-auth": () => import("/build/ff7be446-e19f-4a35-9cf1-236500a00160/middleware/skip-auth.ts")
}